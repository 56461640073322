import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";


import { EditIcon}  from "../common/Icon";

const EditableField = ({ label, field, value, isEditable, onChange, handleEditClick }) => (
  <div style={{ marginBottom: '10px' }}>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      <span style={{ fontSize: '0.875rem', fontWeight: '500', color: 'gray' }}>{label}</span>
    </div>
    <div style={{ position: 'relative' }}>
    {field === "note" ? (
        <textarea
          value={value|| ""}
          placeholder={value|| ""}
          disabled={!isEditable}
          onChange={(e) => onChange(e.target.value)}
          style={{
            width: '100%',
            padding: '20px',
            height: '12rem',
            cursor: isEditable ? 'text' : 'pointer',
            resize: 'none'
          }}
        />
      ) : (
      <input
        type="text"
        value={value|| ""}
        placeholder={value|| ""}
        disabled={!isEditable}
        onChange={(e) => onChange(e.target.value)}
        style={{ width: '100%', paddingRight: '2rem', cursor: isEditable ? 'text' : 'pointer' }}
      /> )}
      <span onClick={() => handleEditClick(field)} style={{
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%) scaleX(-1)', // Flip vertical
        cursor: 'pointer',
      }}>
         {EditIcon}
        </span>
    </div>
  </div>
);

// Modal Component
const UpdateConfirmationModal = ({ show, onClose, name}) => {
    if (!show) {
      return null;
    }
  
    return (
      <div style={{
        position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
      }}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
          <h4>Confirmation</h4>
          <p>Vos modifications sur {name} sont enregistrées</p>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
             <button className="btn btn-primary" onClick={onClose}>Fermer</button>
          </div>
        </div>
      </div>
    );
  };


  // Modal Component
const DeleteConfirmationModal = ({ show, onClose, onConfirm,name }) => {
    if (!show) {
      return null;
    }
  
    return (
      <div style={{
        position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
      }}>
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
          <h4>Confirmation</h4>
          <p>Êtes-vous sûr de vouloir supprimer {name}?</p>
          <div style={{ display: 'flex', justifyContent: 'space-around', alignItems : 'center' }}>
             <button className="btn btn-primary ms-1 me-4" onClick={onClose}>Annuler</button>
            <button className="btn btn-secondary" onClick={onConfirm}>Confirmer</button>
          </div>
        </div>
      </div>
    );
  };
  

const ApplianceUpdate = () => {
  const [isEditable, setIsEditable] = useState({
    name: false,
    marque: false,
    modele: false,
    dt_fab: false,
    note: false,
    dt_achat: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [showDeleteModal, setShowDeleteModal] = useState(false); // State to control modal visibility
  const [showUpdateModal, setShowUpdateModal] = useState(false); // State to control modal visibility
 
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = () => {
    closeDeleteModal();
    //logout(); // Call logout function on confirmation
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };




  const { appliance } = location.state || {};
  const [currentAppliance, setCurrentAppliance] = useState({
    name: "",
    marque: "",
    modele: "",
    dt_fab: "",
    note: "",
    dt_achat: "",
  });

  const handleFieldChange = (field, value) =>
    setCurrentAppliance((prevAppliance) => ({ ...prevAppliance, [field]: value }));

  const handleEditClick = (field) => {
    console.log('in handle click', field);
    setIsEditable((prevState) => {
      // Toggle the editability of the clicked field
      return { ...prevState, [field]: !prevState[field] };
    });
  };

  useEffect(() => {
    console.log('currentappliance ', appliance);
    setCurrentAppliance(appliance);
  }, [appliance]);


  const updateAppliance= async () => {
    //await AuthService.updateUser(currentUser.id, currentUser.firstname, currentUser.lastname, currentUser.email, currentUser.adresse, currentUser.telephone, currentUser.password);
    setShowUpdateModal(true);
  };

  return (
    <div className="mt-3">
      <div>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            onClick={() => navigate(-1)}
            style={{
              position: 'absolute',
              left: '0',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              fontSize: '24px',
            }}
          >
            &lt;
          </button>
          <h3
            style={{
              margin: '0',
              textAlign: 'center',
              fontSize: '1.25rem',
              fontWeight: '600',
            }}
          >
            Modifier les informations
          </h3>
        </div>

        <form className="mt-4">
          <EditableField
            label="Nom"
            field="name"
            value={currentAppliance.name}
            isEditable={isEditable.name}
            onChange={(value) => handleFieldChange("name", value)}
            handleEditClick={handleEditClick}
          />
          <EditableField
            label="Marque"
            field="marque"
            value={currentAppliance.marque}
            isEditable={isEditable.marque}
            onChange={(value) => handleFieldChange("marque", value)}
            handleEditClick={handleEditClick}
          />
          <EditableField
            label="Modèle"
            field="modele"
            value={currentAppliance.modele}
            isEditable={isEditable.modele}
            onChange={(value) => handleFieldChange("modele", value)}
            handleEditClick={handleEditClick}
          />
          <EditableField
            label="Date d'achat"
            field="dt_achat"
            value={currentAppliance.dt_achat}
            isEditable={isEditable.dt_achat}
            onChange={(value) => handleFieldChange("dt_achat", value)}
            handleEditClick={handleEditClick}
          />
          <EditableField
            label="Date fabrication"
            field="dt_fab"
            value={currentAppliance.dt_fab}
            isEditable={isEditable.dt_fab}
            onChange={(value) => handleFieldChange("dt_fab", value)}
            handleEditClick={handleEditClick}
          />
          <EditableField
            label="Description"
            field="note"
            value={currentAppliance.note}
            isEditable={isEditable.note}
            onChange={(value) => handleFieldChange("note", value)}
            handleEditClick={handleEditClick}
          />
        </form>

        <button className="mt-4 btn btn-primary btn-block" onClick={updateAppliance}>Confirmer</button>
        <button className="mt-3 mb-4 btn btn-primary btn-block" 
            onClick={openDeleteModal} 
            style={{
                background: '#E4626F',
              }}
            >
            Supprimer
        </button>

        {/* Render Delete Confirmation Modal */}
        <UpdateConfirmationModal show={showUpdateModal} onClose={closeUpdateModal} name={currentAppliance.name}/>

        {/* Render Delete Confirmation Modal */}
        <DeleteConfirmationModal show={showDeleteModal} onClose={closeDeleteModal} onConfirm={confirmDelete} name={currentAppliance.name}/>


      </div>
    </div>
  );
};

export default ApplianceUpdate;
