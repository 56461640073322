import React, { Component } from "react";
import Form from "react-validation/build/form";
//import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {Link } from 'react-router-dom';
import AuthService from "../services/auth.service";

//import SwitchButton from './common/SwitchButton';

import { withRouter } from '../common/with-router';

/*
const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
*/
class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
      selectedSwitch : "Connecter",
    };
  }

  required (value) {
    if (!value.toString().trim().length) {
      return 'Ce champ est requis!';
    }
  };
 
  handleSwitchChange (newValue) {
    this.setState({
      selectedSwitch: newValue
    });
    console.log('Sélection actuelle:', newValue); // Vous pouvez gérer la sélection comme nécessaire ici
  };

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  loginDemo(e){
      
    e.preventDefault();
    console.log('logged in demo then go to home ');
          
        AuthService.login('dcaretest@dcareapp.fr', 'dcaretest').then(
          () => {
          console.log('logged in demo then go to home ');
            this.props.router.navigate("/");
              window.location.reload();
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            this.setState({
              loading: false,
              message: resMessage
            });
          }
        );
  }
  

  handleLogin(e) {
    e.preventDefault();
    
    


    if(!this.state.email){
      this.setState({
        loading: false,
        message: "* Email est obligatoire"
      });
      return;
    }
    if(!this.state.password){
      this.setState({
        loading: false,
        message: "* Mot de passe est obligatoire"
      });
      return;
    }


    this.setState({
      message: "",
      loading: true
    });
    
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.email, this.state.password).then(
        () => {
         console.log('logged in the go to home ');
          this.props.router.navigate("/home");
            window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }
/*
 <SwitchButton 
            leftLabel="Enregistrer" 
            rightLabel="Connecter"
            onChange={this.handleSwitchChange} />
            {/* test valeur <p>Option choisie: {selectedSwitch}</p> }

             <h1 className="text-center" style={{ fontSize: '2em', fontWeight: 'normal' }}>Ravi de vous</h1>
          <div className="text-center ">
            <span className="text-center highlight-container-green">
            <h1 className="text-center highlight" style={{ fontSize: '2em', fontWeight: 'bold', color : 'var(--color-default)' }}>Revoir</h1>
            </span>
          </div>
          <br/> <br/> <br/>

*/
  render() {
    return (  
    <div className="mt-3 ">        
      <div className="col-12 col-md-12 col-lg-6 mx-auto">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
          src={process.env.PUBLIC_URL +'icons/Logo_dcare.svg'} 
          alt="DCARE" 
          style={{display: 'flex', justifyContent: 'center', alignContent: 'center',width: '150px' }} />
        </div> 
        <div className="gray-line"></div>
        <div className="mt-3">
          <div className="mb-4">
            <h1 className="text-left mb-1" style={{ fontSize: '1.25em', fontWeight: '700' }}>Se connecter</h1>
            <span className="text-left" style={{ fontSize: '1em', fontWeight: '400' }}>Accédez aux informations de vos appareils et gérez-les de manière plus écologique et efficace</span>
          </div>
          {this.state.message && (
             
                <div className='mb-3' style={{ fontSize: '1.25em', fontWeight: '400' ,color:'var(--color-red)',textDecoration: 'none' }} role="alert">
                  {this.state.message}
                </div>
  
            )}
          <Form
            onSubmit={this.handleLogin}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
            <span className="text-left" style={{ fontSize: '1em', fontWeight: '400' }}>Email</span>
               <div className="mt-1 d-flex gray-border ">
                  <div className="col-11">
                  <input
                    placeholder="Entrez votre email"
                    type="text"
                    name="email"
                    className="input-login"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    autoComplete="username"
                  />
                  </div>
              </div>               
            </div>

            <div className="mt-4 form-group">
            <span className="text-left" style={{ fontSize: '1em', fontWeight: '400' }}>Mot de passe</span>
              <div className="mt-1 d-flex gray-border ">
              <input
                    type="password"
                    placeholder="Entrez votre mot de passe"
                    className="input-login"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    autoComplete="current-password"
                  />
               
              </div>
            </div>

            <div className="mt-4 mx-auto">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>VALIDER</span>
                    </button>
            </div>
            <div className="text-center mt-4 mb-6">
            <Link  to={"/register"} style={{ fontSize: '0.875rem', color : 'black', textDecoration:'none'}}>Mot de passe oublié ?</Link>
            </div>
          </Form>
        </div>
          <div className="mt-4 text-center ">
              <span style={{ fontSize: '0.875rem',}}> Pas de compte ? Créez-en un en quelques clics ! </span>  
              <Link to={"/register"} style={{fontSize: '0.875rem',color:'var(--color-green)',textDecoration: 'none',fontWeight:'700',paddingRight: '10px' }}>Créer un compte</Link>
            </div>
            <button
                      className="mt-2 btn btn-secondary btn-block" onClick={(e) => this.loginDemo(e)}>
                    DEMO
                  </button>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);