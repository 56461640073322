import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import RefMaintenanceCardService from "../../services/refmaintenancecard.service";
import { useLocation,useNavigate } from 'react-router-dom';


import { capitalizeFirstLetter} from "../../services/glossary.service";



const MaintenanceCard = () => {
  const location = useLocation();
  const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
  const { product } = state|| {}; // S'assurer que state n'est pas null avant de déstructurer
  console.log("product reçu ",product);
  

  // Convertir prodName en minuscules
  const prodNameLowerCase = product.prod_name.toLowerCase();

  const navigate = useNavigate();

  // Utilisation de useState pour stocker la liste des cartes de maintenance
  const [maintenanceCardList, setMaintenanceCardList] = useState([]);


  console.log('ID du produit sélectionné reçu:', product.prod_id);
  
  
  const handleClick = (maintenanceCard) => {
    // Rediriger vers la page CardDetail et passer l'objet maintenanceCard
    navigate(`/entretien/fichedetail`, { state: { maintenanceCard : maintenanceCard, appliance : product } });
  };

  useEffect(() => {
    console.log('Dans useeffect **************');
    //test bride à lave-linge
    product.prod_id=1;
    RefMaintenanceCardService.getMaintenanceCardByProduct(product.prod_id).then(
      (response) => {
        setMaintenanceCardList(response.data);
       // console.log('les fiches récupérées:', response.data);
  
      },
      error => {
        console.error('Une erreur s\'est produite:', error);
      }
    );
  }, [product]);

  
  console.log('les fiches récupéré:', maintenanceCardList);
  
  return (
    <div className="mb-4 mt-4">
            <div className="mb-4"
              style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                &lt;
              </button>
              <div style={{ width: '70%', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Entretiens pour votre {prodNameLowerCase}</div>
            </div>
              
                  {maintenanceCardList.map((maintenanceCard) => (  
                    <div key={maintenanceCard.id} className="mb-4 green-border"
                      style={{ fontSize: '0.75rem', fontWeight: '400', padding: '0.5rem' }}
                      onClick={() => handleClick(maintenanceCard)}>
                        <div className="d-flex align-items-center">
                              {/* Colonne gauche - Image avatar */}
                              <div className="ms-1 me-1 text-center">
                                  <img
                                  className="" 
                                  src={maintenanceCard.pict}
                                  alt="Pict"
                                  style={{ width: '6rem', height: 'auto' }}
                                  />
                              </div>

                              {/* Colonne droite - Contenu */}
                              <div className="p-2">
                                <div>{capitalizeFirstLetter(maintenanceCard.title)}</div>
                              </div>
                          </div>
                      </div>  
                  ))}
              </div>
  );
};

export default MaintenanceCard;