import axios from "axios";

import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/`;

class NotifService{

    //count total notif of user
    getNbNotif(){
      const user=JSON.parse(localStorage.getItem('user'));
      const userid=user.id;
      
      const req = {
        "userid":userid
      }

      console.log('getnbnotif sent ',req);
      let url=API_URL+"profil/notif/total";

      const headers=authHeader();
      console.log('Headers:', headers);
      //axios.get(url, { headers, params: req })
      //return axios.get(url,{ headers, pareq });
      // Effectuer la requête GET avec Axios
      return axios.get(url, { headers, params: req })
  }

    //get list notif of user
    getNotif(){
        const user=JSON.parse(localStorage.getItem('user'));
        const userid=user.id;
        
        const req = {
          "userid":userid
        }

        console.log('getnotif sent ',req);
        let url=API_URL+"profil/notif/currentlist";

        const headers=authHeader();
        console.log('Headers:', headers);
        //axios.get(url, { headers, params: req })
        //return axios.get(url,{ headers, pareq });
        // Effectuer la requête GET avec Axios
        return axios.get(url, { headers, params: req })
    }


    async readNotif(id) {
        const user=JSON.parse(localStorage.getItem('user'));
        
        const req = {
          "status" : "READ"
        }

        console.log('saveNotif sent ',req);
        let url=API_URL+"Notif";
        // Effectuer une requête PUT vers le backend avec les nouvelles informations utilisateur
        const updatedNotifFromBackend = await axios.put(API_URL + `profil/notif/${id}`, req).then(response => {
          return response.data;
        });

        console.log('Notif updated frombackend ',updatedNotifFromBackend);
        return updatedNotifFromBackend; 
    }

    
}
// Créez une instance de NotifService
const notifServiceInstance = new NotifService();

// Exportez l'instance en tant qu'export par défaut
export default notifServiceInstance;