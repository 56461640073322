import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/`;

class UniverService{


  getUniver() {
    // Récupérer l'utilisateur et son ID depuis localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userid = user?.id;

    // Vérifier si l'utilisateur est défini avant de continuer
    if (!userid) {
        console.error("User ID not found in localStorage");
        return Promise.reject("User ID not found");
    }

    // Construire l'URL avec l'ID utilisateur
    const url = `${API_URL}profil/${userid}/foyer/univer/listcompleted`;

    // Récupérer les headers d'authentification
    const headers = authHeader();

    // Effectuer la requête GET avec Axios
    return axios.get(url, { headers })
        .then(response => {
            // Gérer la réponse ici si besoin
            
            return response.data;
        })
        .catch(error => {
            // Gérer l'erreur ici
            console.error("Error fetching univers:", error);
            throw error;
        });
  }

   
    
}


// Créez une instance de NotifService
const UniverServiceInstance = new UniverService();

// Exportez l'instance en tant qu'export par défaut
export default UniverServiceInstance;


//export default new EventService();