import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import Navigation from "./navigation";
import { EditIcon}  from "./common/Icon";



const EditableField = ({ label, icon, field, value, isEditable, onChange, handleEditClick }) => (
  <div style={{ marginBottom: '10px' }}>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
      <img src={process.env.PUBLIC_URL + icon} alt={label} style={{ width: 'auto', height: '1rem', marginRight: '0.125rem' }} />
      <span style={{ fontSize: '0.875rem', fontWeight: '500', color: 'gray' }}>{label}</span>
    </div>
    <div style={{ position: 'relative' }}>
      <input
        type="text"
        value={value}
        placeholder={value}
        disabled={!isEditable}
        onChange={(e) => onChange(e.target.value)}
        style={{ width: '100%', paddingRight: '2rem' }}
      />
      <span onClick={() => handleEditClick(field)} style={{
        position: 'absolute',
        right: '10px',
        top: '50%',
        width: '1.5rem',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
      }}>
       {EditIcon}
      </span>
    </div>
  </div>
);

// Modal Component
const LogoutConfirmationModal = ({ show, onClose, onConfirm }) => {
  if (!show) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)'
    }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
        <h4>Confirmation</h4>
        <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems : 'center' }}>
           <button className="btn btn-primary ms-1 me-4" onClick={onClose}>Annuler</button>
          <button className="btn btn-secondary" onClick={onConfirm}>Confirmer</button>
        </div>
      </div>
    </div>
  );
};

// Modal Component
const UpdateConfirmationModal = ({ show, onClose}) => {
  if (!show) {
    return null;
  }

  return (
    <div style={{
      position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
      display: 'flex', justifyContent: 'center', alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)'
    }}>
      <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
        <h4>Confirmation</h4>
        <p>Vos modifications sont enregistrées</p>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
           <button className="btn btn-primary" onClick={onClose}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

const Profile = () => {
  const [redirect, setRedirect] = useState(null);
  const [userReady, setUserReady] = useState(false);
  const [currentUser, setCurrentUser] = useState({ username: "", firstname: "", lastname: "", email: "", adresse: "", telephone: "", password: "" });
  const [isEditable, setIsEditable] = useState({ firstname: false, lastname: false, email: false, adresse: false, telephone: false, password: false });
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false); // State to control modal visibility
  const [showUpdateModal, setShowUpdateModal] = useState(false); // State to control modal visibility
 
  const openLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const confirmLogout = () => {
    closeLogoutModal();
    logout(); // Call logout function on confirmation
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
  };

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) setRedirect("/login");
    else {
      setCurrentUser(currentUser);
      setUserReady(true);
    }
  }, []);

  const handleFieldChange = (field, value) => setCurrentUser((prevUser) => ({ ...prevUser, [field]: value }));
  const handleEditClick = (field) => setIsEditable((prevState) => ({ ...prevState, [field]: !prevState[field] }));

  const logout = async () => {
    await AuthService.logout();
    navigate("/login");
  };

  const updateuser = async () => {
    await AuthService.updateUser(currentUser.id, currentUser.firstname, currentUser.lastname, currentUser.email, currentUser.adresse, currentUser.telephone, currentUser.password);
    setShowUpdateModal(true);
  };

  if (redirect) return <Navigate to={redirect} />;

  return (
    <div className="mt-3">
      {userReady && (
        <div>
          <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
              &lt;
            </button>
            <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Mon profil</h3>
          </div>
          <div className="mt-3 mb-3" style={{ color: 'black', fontSize: '0.875rem', fontWeight: '500' }}>Mes coordonnées</div>

          <form className="mb-3">
            <EditableField label="Prénom" icon="/icons/Profile_green.svg" field="firstname" value={currentUser.firstname} isEditable={isEditable.firstname} onChange={(value) => handleFieldChange('firstname', value)} handleEditClick={handleEditClick} />
            <EditableField label="Nom" icon="/icons/Profile_green.svg" field="lastname" value={currentUser.lastname} isEditable={isEditable.lastname} onChange={(value) => handleFieldChange('lastname', value)} handleEditClick={handleEditClick} />
            <EditableField label="Adresse email" icon="/icons/Email_green.svg" field="email" value={currentUser.email} isEditable={isEditable.email} onChange={(value) => handleFieldChange('email', value)} handleEditClick={handleEditClick} />
            <EditableField label="Mot de passe" icon="/icons/Password_green.svg" field="password" value={currentUser.password} isEditable={isEditable.password} onChange={(value) => handleFieldChange('password', value)} handleEditClick={handleEditClick} />
            <EditableField label="Adresse" icon="/icons/Home_green.svg" field="adresse" value={currentUser.adresse} isEditable={isEditable.adresse} onChange={(value) => handleFieldChange('adresse', value)} handleEditClick={handleEditClick} />
            <EditableField label="Téléphone" icon="/icons/Telephone_green.svg" field="telephone" value={currentUser.telephone} isEditable={isEditable.telephone} onChange={(value) => handleFieldChange('telephone', value)} handleEditClick={handleEditClick} />
          </form>

          <button className="mt-4 btn btn-primary btn-block" onClick={updateuser}>Sauvegarder</button>
          <button className="mt-4 mb-5 btn btn-secondary btn-block" onClick={openLogoutModal}>
            Déconnecter
            <img src={process.env.PUBLIC_URL + '/icons/Logout.svg'} alt="Exit" style={{ width: '1.5rem', height: '1.5rem', marginLeft: '5px', verticalAlign: 'bottom' }} />
          </button>
           {/* Render Logout Confirmation Modal */}
           <LogoutConfirmationModal show={showLogoutModal} onClose={closeLogoutModal} onConfirm={confirmLogout} />

           {/* Render Logout Confirmation Modal */}
           <UpdateConfirmationModal show={showUpdateModal} onClose={closeUpdateModal} />
      
        </div>
      )}
    </div>
  );
};

export default Profile;
