import React, { useEffect, useState,useRef } from "react";
import { Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation, useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import getLabel from "../../services/glossary.service";
import Calendar from 'react-calendar';
import "../../css/calendar.css";
import eventService from "../../services/event.service";
import Icon  from "../common/Icon";


const MaintenanceCardDetail = () => {
  const location = useLocation();
  const { state } = location;
  const { maintenanceCard, appliance } = state;
  const navigate = useNavigate();

  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalPlanning, setShowModalPlanning] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // État pour stocker l'index de la diapositive actuellement affichée
  const [selectedDate, setSelectedDate] = useState(new Date());
  const sliderRef = useRef(null);

  const [textInput, setTextInput] = useState('');

    const handleInputChange = (e) => {
        setTextInput(e.target.value);
    };


  /*handle modal close for all modal*/  
  const handleClose = () => {
    setShowModal(false);
    setShowModalPlanning(false);
  };

  const handleShow = () => {
    setShowModal(true);
    setShowModalPlanning(false);
  };

  const handleShowPlanning = () => {
    setShowModal(false);
    setShowModalPlanning(true);
  };
  const handleShowFinish = () => {};

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSaveEvent = (dt_planned,appliance,maintenanceCard) => {
    const currentDate = new Date();
    if (appliance){
      eventService.saveMaintenanceEvent(currentDate,dt_planned,maintenanceCard.title,textInput,-1,maintenanceCard.id);
    }else{
    //applianceId inconnu -1
      eventService.saveMaintenanceEvent(currentDate,dt_planned,maintenanceCard.title,textInput,-1,maintenanceCard.id);
    }
    setShowModal(false);
    setShowModalPlanning(false);
  };


  
    useEffect(() => {
    console.log('Dans useEffect CardDetail:');
    
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992); // Vous pouvez ajuster ce point de rupture selon vos besoins
    };

    handleResize(); // Appeler la fonction initiale pour définir l'état initial

    window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!state || !state.maintenanceCard) {
    // Gérer le cas où state ou maintenanceCard n'est pas défini
    return <div>Erreur : Aucune donnée de maintenanceCard trouvée</div>;
  }
  

  

  const settings = {
    dots: false,
    infinite:false ,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // Masquer les flèches
    adaptiveHeight: true,
    afterChange: (index) => {
      setCurrentSlideIndex(index); // Mettre à jour l'index de la diapositive actuellement affichée
    },
  };

  console.log('Carte reçue:', maintenanceCard);
  // Supposons que maintenanceCard.pict contient la chaîne d'URLs séparées par des points-virgules
  //const imageLinks = maintenanceCard.pict.split(';');

  return (
    <div className="mb-4 mt-4">
            <div className="mb-4"
              style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                &lt;
              </button>
              <div style={{ width:'70%', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>Fiche d'entretien</div>
            </div>
          <div >
          <div className="mb-4" style={{ width:'100%', fontSize: '1rem', fontWeight: '600' }}>{maintenanceCard.title} {appliance.name}</div>
            <div>
              <span style={{ fontSize: '0.875rem', fontWeight: '500' }}>Information</span>
                  <div className={`col-12 col-md-6 col-lg-6 mt-2 mb-3 ${isLargeScreen ? '' : ''}` }>
                    <div className="fiche align-items-center pt-2 pb-2 px-3 h-100"
                    style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                        <div className="mb-2 col d-flex">
                              <span className="mr-2">Durée :</span>
                              <span style={{fontWeight: '500' }}>&nbsp;{maintenanceCard.duration} minutes</span>
                            </div>
                            <div className="mb-2 col d-flex">
                                  <span>Fréquence :</span>
                                  <span style={{fontWeight: '500' }}>&nbsp;{maintenanceCard.frequency} jours</span>
                              </div>
                              <div className="col d-flex">
                                  <span>Difficulté :</span>
                                  <span style={{fontWeight: '500' }}>&nbsp;{getLabel(maintenanceCard.level)}</span>
                              </div>
                      </div>
                    </div>
              </div>
                  
              <div className='row'>
                <div className={`d-flex justify-content-between align-items-end mt-3 col-12 col-lg-6` }>
                  <div style={{ fontSize: '0.875rem', fontWeight: '500' }}>Produits nécessaires</div>
                  <div 
                    style={{ fontSize: '0.875rem', 
                      fontWeight: '400',
                      border: '1px solid black',  // Ajoute une bordure
                      borderRadius: '50%',        // Rend la bordure circulaire
                      width: '1rem',            // Largeur du cercle
                      height: '1rem',           // Hauteur du cercle
                      display: 'flex',            // Utilisation de flexbox pour centrer le contenu
                      justifyContent: 'center',   // Centre horizontalement
                      alignItems: 'center',       // Centre verticalement
                      lineHeight: '1rem',         // Ajuste la hauteur de ligne pour centrer le texte
                      textAlign: 'center'         // Centre le texte dans le div
                      }}>!</div>
                </div>
                <div style={{ fontSize: '0.75rem', fontWeight: '500' }}>{maintenanceCard.agentlist.length} produits</div>
              </div> 

              <div className="mt-2 d-flex row gx-3">
                {maintenanceCard.agentlist.map((agent) => (
                  <div key={agent.id} className="col-4 col-md-4 col-lg-3 product-link" >
                    <div className="d-flex flex-column justify-content-between align-items-center h-100" style={{ padding:'5px', border: '1px solid var(--color-green)'}}>
                    <img style={{ with: '6rem', height: '6rem' }} src={process.env.PUBLIC_URL + '/icons/image/'+agent.pict} alt="" className="img-fluid mb-2"/> 
                      <div  className="text-center" style={{fontSize: '0.75rem', fontWeight: '500', wordWrap: 'break-word' }}>
                        {agent.name}
                      </div>
                      {/* On s'assure que cet élément reste aligné en bas */}
                      <div className="mt-auto" style={{ fontSize: '0.75rem', fontWeight: '400' }}>
                        {agent.volume}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-4 mb-4" style={{ fontSize: '0.875rem', fontWeight: '500' }}>J'ai tous les produits nécessaires. Je peux procéder à l'entretien !</div>

              <div className={`align-items-center justify-content-center col-12 col-md-4 col-lg-4 ${isLargeScreen ? '' : ''}`}>
                <button 
                        className="btn btn-primary btn-block mx-auto" onClick={handleShow}>
                            Commencer
                </button>
                <button 
                        className="mt-3 btn btn-secondary btn-block mx-auto" onClick={handleShow}>
                            Planifier cet entretien
                </button>
              </div> 

              {/*popup maintenance step*/}
              <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <div className="col d-flex justify-content-between align-items-center">
                    {/* Bouton Previous */}
                    <div
                        className="icone-center" 
                        alt="Previous"
                        onClick={() => sliderRef.current.slickPrev()}
                        style={{textAlign:'center', cursor: 'pointer' }} // Ajoutez cette ligne pour changer le curseur
                    >
                      &lt;
                    </div>
                      {/* Bouton Next */}
                      {/* Numéro de la diapositive actuelle / Nombre total de diapositives */}
                    <div>{currentSlideIndex + 1} / {maintenanceCard.agentlist.length+1}</div>
                    <div
                        className="icone-center" 
                        alt="Next"
                        onClick={() => sliderRef.current.slickNext()}
                        style={{ textAlign:'center', cursor: 'pointer' }} // Ajoutez cette ligne pour changer le curseur
                    >
                      &gt;
                    </div>  
                  </div>
                </Modal.Header>
                <Modal.Body>
                <Slider ref={sliderRef} {...settings} >
                            {maintenanceCard.steplist
                            .slice() // Créez une copie du tableau pour éviter de modifier l'original
                            .sort((a, b) => a.steporder - b.steporder) // Triez selon l'ordre 
                            .map((step) => ( 
                            <div key= {step.id}>
                              <div>
                                <div className="d-flex align-items-center mb-4">
                                  <div className="text-center">
                                  <h1 className="order-circle">{step.steporder}</h1>
                                  </div>
                                  <div className="ms-3 ps-3">
                                  <h1>{step.title}</h1>
                                  </div>
                                </div>
                                {step.video && step.video.trim() !== "" && (
                                  <div className="mb-4" align="justify">
                                    <video width="100%" controls>
                                      <source src={step.video} type="video/mp4" />
                                      Your browser does not support the video tag.
                                    </video>
                                  </div>
                                )}
                                <div className="mb-4" align="justify">{step.detail}</div>
                                <div>
                                <div className="row ">
                                  {step.pict.split(';').map((imageLink, index) => (
                                    <div key={index} className="col-12 col-md-6 col-lg-6 mb-3">
                                      <img src={imageLink} alt={`Step ${step.title} - ${index + 1}`} className="step-image mb-3" loading="lazy"/>
                                    </div>
                                  ))}
                                </div>
                                </div>
                              </div>
                            </div>
                            ))} 
                            {/* resume slide at the end */}
                              <div className="d-flex align-items-center justify-content-center px-2">
                                {/* resume slide  */}
                                <div className="text-center">
                                  <div 
                                    className="text-center mb-4"
                                    style={{ textAlign: 'center', fontSize: '4rem', fontWeight: '600' }}>
                                      &#x1F3C6;
                                  </div>
                                  <h1 className="mb-3 text-center">Bravo, entretien effectué !</h1>
                                  <p>Félicitations pour avoir terminé l'entretien de votre appareil ! Ce geste éco-responsable contribue non seulement à prolonger la durée de vie de votre équipement, mais aussi à préserver l'environnement.</p>
                                  <div 
                                    className="text-center mb-4"
                                    style={{ textAlign: 'center', fontSize: '4rem', fontWeight: '600' }}>
                                      &#x1F3C5;
                                  </div>
                                  <span align="center">Souhaitez-vous planifier cet entretien tous les 3 mois ?</span>
                                  
                                  <div className={`mb-4 col-12 col-md-12 col-lg-12 text-center ${isLargeScreen ? 'pr-0' : 'pr-2'}`}>
                                      <button variant="secondary"
                                              className="mt-3 btn btn-primary btn-block mx-auto" onClick={handleShowPlanning}
                                              style={{ textAlign: 'center', fontSize: '0.75rem', fontWeight: '600', height:'auto' }}>
                                                  Oui, planifier l'entretien récurrent
                                      </button>
                                    </div> 
                                    
                                    <span align="center">Ou souhaitez-vous le planifier à une autre date ?</span>
                                    <div className={`mb-4 col-12 col-md-12 col-lg-12 mx-auto ${isLargeScreen ? 'pr-0' : 'pr-2'}`}>
                                    <button variant="secondary"
                                            className="mt-3 btn btn-primary btn-block mx-auto" onClick={handleShowFinish}
                                            style={{ textAlign: 'center', fontSize: '0.75rem', fontWeight: '600',height:'auto' }}>
                                                Je planifie l'entretien un autre jour
                                    </button>
                                    </div> 
                                    
                                </div>
                              </div>
                    </Slider>
                    <br/> <br/> <br/>
                </Modal.Body>
              </Modal>          
              

              {/* popup maintenance planner*/}
              <Modal show={showModalPlanning} onHide={handleClose} className="modal-maintenance px-2" activeclassname="active">
                <Modal.Header closeButton>
                  <div className="text-center">
                  <h1 >Planifier une date pour " {maintenanceCard.title.toLowerCase()} "</h1>
                  </div>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                <div className={`col-12 col-md-6 col-lg-4 mb-3 ${isLargeScreen ? 'px-3' : 'px-3'}`} > 
                  <h1 mb-3>Sélectionnez une date</h1>
                    <Calendar className="mt-3"
                      onChange={handleDateChange}
                      value={selectedDate}
                      locale="fr-FR" // Utilisez la notation BCP 47 pour la langue française
                    />
                  </div>
                  <div className={`col-12 col-md-6 col-lg-6 mb-3 ${isLargeScreen ? 'px-3' : 'px-3'}`} > 
                    {selectedDate && (
                      <div>
                        <h1>Date sélectionnée :</h1>
                          <p  className="mt-2 " >{`${selectedDate.getDate()} - ${selectedDate.toLocaleDateString('fr-FR', { month: 'long' })} - ${selectedDate.getFullYear()}`}</p>
                          <div>
                            <span  className="mt-2 mb-2" style={{ display: 'block' }}>Ajoutez une note</span>
                            <textarea
                              className="editable-content mb-2 mt-0"
                              style={{
                                  width: `${isLargeScreen ? '50%' : '100%'}`,
                                  minHeight: '100px',
                                  padding: '8px',
                                  border: '1px solid var(--color-secondary)',
                                  borderRadius: '4px',
                                  direction: 'ltr'
                              }}
                              value={textInput}
                              onChange={handleInputChange}
                          />
                            </div>
                          <div  className="col-6 mt-2">
                          <button variant="secondary"
                            className="btn btn-primary btn-block px-auto" onClick={() => handleSaveEvent(selectedDate,appliance,maintenanceCard)}>
                            Valider
                          </button>
                          </div>
                        </div>
                      )}
                      </div>
                  </div>
                    <br/> <br/> <br/>
                </Modal.Body>
              </Modal> 
          </div>
        </div>
  );
};

export default MaintenanceCardDetail;