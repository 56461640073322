import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";
import { useLocation,useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap"; 


import RefproductService from "../../services/refproduct.service";

import ApplianceService from "../../services/appliance.service";
import AuthService from "../../services/auth.service";

import SwitchButton from "../common/SwitchButton";


const ProductList = () => {
  
  const [refProductList, setRefProductList] = useState([]);
  const [userProductList, setUserProductList] = useState([]);

  // État pour stocker le terme de recherche
  const [searchTerm, setSearchTerm] = useState('');
  const [voirPlus, setVoirPlus] = useState(false);
  const [currentList, setCurrentList] = useState('Produits génériques');



  const location = useLocation();
  const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;

  const { toadd, tosecond, tomaintenance } = state || {};



  const navigate = useNavigate();
  

  const handleClicktoAdd = (product) => {
    // Pour le moment on bride sur prodName: "Lave-Linge"
    const selectedProduct = refProductList.find(product => product.prodName === "Lave-Linge");

    const updateproduct = {
      prod_id: selectedProduct.id,
      prod_name: selectedProduct.prodName
    };
    //redirection vers la page d'ajout du produit
    if (toadd) navigate(`/inventaire/nouveau_2`, { state: { product } });
    else if (tosecond) navigate(`/secondlife/actionselect`, { state: { product: updateproduct } });
    //redirection vers la page des fiches de maintenance
    else navigate(`/entretien/fiche`, { state: { product: updateproduct } });
  };

  const handleSwitchChange = (selectedList) => {
    console.log("change list", selectedList);
    setCurrentList(selectedList);
  };

  // Gestionnaire d'événements pour mettre à jour le terme de recherche
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setVoirPlus(false); // Réinitialiser voirPlus à chaque nouvelle recherche
  };

  // Gestionnaire d'événements pour mettre à jour le terme de recherche
  const handleVoirPlus = (bool) => {
    if (bool) setSearchTerm('');
    setVoirPlus(bool); // Réinitialiser voirPlus à chaque nouvelle recherche
  };

  
  
  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';

    const user = AuthService.getCurrentUser();
    console.log('User reçue:', user);
    

    console.log('Dans inventaire des produits');


    const fetchProductList = async () => {
      try {
          const refProductList = await RefproductService.getAllProduct();  // Récupérer les refproduct
          if (Array.isArray(refProductList.data)) {
            setRefProductList(refProductList.data);  // Mettre à jour l'état avec les refproductlist
          } else {
            console.error("Les données renvoyées ne sont pas un tableau :", refProductList);
          }
          const user = AuthService.getCurrentUser();
          const userProductList = await ApplianceService.getApplianceByUser(user.id);
          console.log("userProduct ", userProductList)
          setUserProductList(userProductList.data);
      } catch (error) {
          console.error("Erreur lors de la récupération des listes de produit:", error);
      }
    };

    fetchProductList();  // Appel de la fonction asynchrone pour récupérer les univers

  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial


  // Adapter la structure de userProductList pour qu'elle corresponde à refProductList
  const adaptedUserProductList = userProductList.map(product => ({
    prodName: product.prod_name, // Adapter le nom de la propriété
    pict: product.pict, // Garder les autres propriétés telles quelles
    name: product.name,
    id: product.id // Garder l'id si nécessaire
  }));



    // Déterminer quelle liste afficher en fonction du switch
  const listToDisplay = currentList === "Produits génériques" ? refProductList : adaptedUserProductList;

  // Filtrer la liste basée sur le terme de recherche
  const filteredProducts = Array.isArray(listToDisplay) 
    ? listToDisplay.filter((product) => product.prodName.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];


  // Déterminer les produits à afficher en fonction de voirPlus et searchTerm
  const produitsAAfficher = voirPlus || searchTerm ? filteredProducts : filteredProducts.slice(0, 5);


  
  return (

    <div className="mb-4 mt-4">
          <div className="justify-content-center mb-4"> 
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                            &lt;
                          </button>
                          {(tomaintenance) && ( <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600',width:'70%'  }}>Choisir le produit à entretenir</h3> )}
                          {(toadd) && ( <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600',width:'70%'  }}>Choisir le produit à ajouter</h3> )}
                          {(tosecond) && ( <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600', width:'70%' }}>Choisir le produit pour lui donner une seconde vie</h3> )}
              </div>
           
            <div  className="product-container mt-4 mb-4"> 
            <SwitchButton
                leftLabel="Produits génériques"
                rightLabel="Produits enregistrés"
                onChange={handleSwitchChange}
            />

                {/* Champ de recherche */}
              <div className="search-bar mb-4">
                <input
                  type="text"
                  placeholder="Rechercher un produit..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="form-control"
                />
              </div>


                <div className="d-flex row gx-3"> 
                  {produitsAAfficher.map((product) => (
                      <div key={product.id} className="col-6 col-md-4 col-lg-3 product-link mb-3" onClick={() => handleClicktoAdd(product)}>
                          <div className="product-background text-center "
                          style={{ height : "100%"}}>
                            <img src={product.pict} alt={product.prodName} className="img-fluid mb-3"/>
                            <span className="fw-bold">{product.prodName}</span>
                            {(product.name) && (<div>{product.name}</div>)}
                            </div>
                      </div>
                  ))}
                </div>
                {(!voirPlus) && (
                <Button variant="secondary" className="btn btn-primary mb-10" onClick={() => handleVoirPlus(true)}>Voir plus</Button>
                )}
                {(voirPlus) && (
                <Button variant="secondary" className="btn btn-primary mb-10" onClick={() => handleVoirPlus(false)}>Voir Moins</Button>
                )}
            </div>
        </div>
      </div>
  );
};

export default ProductList;

