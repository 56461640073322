import React from 'react';
import { Link } from 'react-router-dom';
//import InstallButton from "./InstallButton";
//import 'bootstrap/dist/css/bootstrap.min.css'; // Importez le fichier CSS de Bootstrap


const Header = () => {
  console.log("Show header");
  return (
    <div className="mt-2 header d-block d-lg-none"> {/* Applique la classe d-block d-lg-none ou 'd-md-none' pour cacher sur les écrans de taille moyenne et supérieure */}
        <div className="mb-2 container">
              <div className="mt-2 d-flex align-items-center justify-content-between">
                <div>
                  <img src={process.env.PUBLIC_URL + '/icons/Logo_dcare.svg'} style={{ width: 'auto', height: '20px', verticalAlign: 'middle' }} alt="logo"/>
                </div>  
                <div>
                  <Link to="/profile">
                    <img src={process.env.PUBLIC_URL + '/icons/Profile.svg'} style={{ width: 'auto', height: '20px', verticalAlign: 'middle' }} alt="profil" />
                  </Link>
                </div>
              </div>
        </div>
  </div>
  );
};

export default Header;
