import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import ApplianceService from "../../services/appliance.service";

import AuthService from "../../services/auth.service";
import EventBus from "../../common/EventBus";

const UniverDetail = () => {
  // Récupérer l'objet univer depuis l'état de location
  const location = useLocation();
  const { univer } = location.state || {}; // L'objet univer est dans location.state
  const [productList, setProductList] = useState([]);
  
  const navigate = useNavigate(); 


  useEffect(() => {
    console.log('Dans inventaire des produits');
    document.body.style.backgroundColor = '#ffffff';

    const user = AuthService.getCurrentUser();
    console.log('User reçue:', user);
    console.log('Dans inventaire des produits');
   
    ApplianceService.getApplianceByUserAndUniver(user.id, univer.univer_id)
      .then((response) => {
        setProductList(response.data);
        console.log('Dans inventaire des produits', response.data);
      })
      .catch((error) => {
        console.error('Une erreur s\'est produite:', error);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      });
  }, []); // Le tableau vide en second paramètre signifie que useEffect s'exécutera une fois après le montage initial




  // Si aucun univers n'est sélectionné
  if (!univer) {
    return <div>Aucun univers sélectionné</div>;
  }

  // Fonction appelée lorsqu'un univers est cliqué
  const gotoProduct = (currentProduct) => {
    console.log("appareil sent ",currentProduct);

    navigate(`/inventaire/univers/appliance/fichedetail`, { state: { appliance: currentProduct } });
  };

  
  return (
         <div className="container mt-4">
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={() => navigate(-1)} style={{ position: 'absolute', left: '0', border: 'none', background: 'none', cursor: 'pointer', fontSize: '24px' }}>
                &lt;
              </button>
              <h3 style={{ margin: '0', textAlign: 'center', fontSize: '1.25rem', fontWeight: '600' }}>{univer.name}</h3>
            </div>
           {/* Affichage de la liste des univers */}
           <div className="mt-4 d-flex row gx-2 mb-2 h-100">
                    {productList.map((product, index) => (
                        <div className="col-6 col-md-3 col-lg-3 text-center mb-3 pe-2" 
                            key={index}
                            onClick={() => gotoProduct(product)}
                            style={{ cursor: 'pointer' }}>
                            <div className="product-background mb-1"
                            style={{ height : "100%"}}>
                                <img 
                                    src={product.pict} 
                                    alt={product.prod_name} 
                                    className="img-fluid mb-2" 
                                />
                                <div style={{ fontSize: '0.875rem', fontWeight: '600' }}>{product.prod_name}</div>
                                <div style={{ fontSize: '0.75rem', fontWeight: '400' }}>{product.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
          </div>
        
  );
};

export default UniverDetail;
