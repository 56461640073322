import axios from 'axios';
import authHeader from './auth-header';
// Dans le fichier où vous avez besoin de l'adresse IP
import config from './config';

const API_URL = `${config.serverIP}/api/user/appliance`;

class ApplianceService {
  getApplianceByUser(userid) {
    console.log('ID du user à récupérer :', userid);
    let url=API_URL+'/list?userid='+userid;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  getApplianceByUserAndUniver(userid, univerid) {
    console.log('ID du user à récupérer :', userid);
    let url=API_URL+'/list?userid='+userid+'&univerid='+univerid;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  getApplianceDetail(id) {
    console.log('ID appareil à récupérer :',id);
    let url=API_URL+'/detail?id='+id;
    console.log('url  :', url);
    return axios.get(url,{ headers: authHeader() });
  }

  addAppliance(appliance) {
    const request={
      "Prod_id" : appliance.Prod_id,
      "Prod_name" : appliance.Prod_name,
      "Name" : appliance.Name,
      "Dt_achat" : appliance.Dt_achat,
      "Dt_creation" : appliance.Dt_creation,
      "Facture" : appliance.Facture,
      "Notice" : appliance.Notice,
      "Note" : appliance.Note,
      "Pict" : appliance.Pict
    }
    let url=API_URL+'/new';
    
    console.log('request  :', request);
    return axios.post(API_URL,request,{ headers: authHeader() });
  }

  


  uploadFacture(formData){
    try {
      const response = axios.post('URL_DE_VOTRE_API', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data);
      // Gérez la réponse de succès ici
    } catch (error) {
      console.error(error);
      // Gérez l'erreur ici
    }
  }
}

// Créez une instance de NotifService
const ApplianceServiceInstance = new ApplianceService();

// Exportez l'instance en tant qu'export par défaut
export default ApplianceServiceInstance;

//export default new ApplianceService();