import React from 'react';
import { useLocation } from 'react-router-dom';

const UniverCreate = () => {
  // Récupérer l'objet univer depuis l'état de location
  const location = useLocation();
  const { univer } = location.state || {}; // L'objet univer est dans location.state

  // Si aucun univers n'est sélectionné
  if (!univer) {
    return <div>Aucun univers sélectionné</div>;
  }

  return (
    <div className="mt-4">
      <div className="text-center">
        {/* Afficher l'image de l'univers */}
        <img
          src={process.env.PUBLIC_URL + '/icons/image/' + univer.image}
          alt={univer.name}
          className="img-fluid mb-3"
          style={{ maxWidth: '300px' }}
        />
        
        {/* Afficher le nom de l'univers */}
        <h1>{univer.name}</h1>
        
        {/* Afficher le nombre d'appliances */}
        <p>
          {univer.nbAppliance === 0
            ? 'Vide'
            : univer.nbAppliance === 1
            ? '1 appareil'
            : `${univer.nbAppliance} appareils`}
        </p>
      </div>
    </div>
  );
};

export default UniverCreate;
