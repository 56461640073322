import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import AuthService from '../services/auth.service'; 
import UserService from '../services/user.service'; 
import AppBadge from '../components/common/AppBadge'; 
import Navigation from "./navigation";
import Header from "./common/Header";
import Banner from "./common/Banner";
import { Link,useNavigate } from 'react-router-dom';


import { useLocation } from 'react-router-dom';
import { withRouter } from '../common/with-router';


const Home = () => {


  const location = useLocation();
  const { state } = location|| {}; // S'assurer que state est un objet si location.state est null;
 
  const navigate = useNavigate();
  
  const [content, setContent] = useState("");
  const [isFreemium, setIsFreemium] = useState(false);
  const [isAssure, setIsAssure] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [username, setUsername] = useState("");
  const [badges, setBadges] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  /**Contruction chart */
  const chartType = 'doughnut';
  const labels = ['PEM', 'GEM'];
  const data = [10, 20];
  // Utiliser les couleurs personnalisées
  const backgroundColors = ['#4FB573', '#E2F3E8']; // Couleurs spécifiées


  // Construire les parties de l'URL
  const quickChartBaseUrl = "https://quickchart.io/chart?v=3&c=";
  const chartConfig = {
    type: chartType,
    data: {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: backgroundColors, // Spécifier les couleurs ici
        borderRadius: 20, 
        borderWidth: 0, // Ajouter un espace entre les segments (ajustez cette valeur selon votre besoin)
        borderColor: '#FFFFFF', // Utilisez une couleur blanche pour l'espace entre les segments (ou une autre couleur si vous préférez)
        borderJoinStyle: 'round', // Arrondir les jointures des segments
        spacing: 5, // Espace entre les segments
      }]
    },
    options: {
      cutout: '80%', // Amincir le doughnut (80% d'ouverture)
      plugins: {
        datalabels: {
          display: false // Désactiver les labels sur les segments
        },
        legend: {
          display: false // Désactiver la légende
        }
      },
      elements: {
        arc: {
          borderRadius: 20, // Arrondir les coins des segments
        }
      },
    },
  };

  // Encoder l'objet chartConfig en chaîne JSON pour l'URL
  const chartUrl = quickChartBaseUrl + encodeURIComponent(JSON.stringify(chartConfig));
      

  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff';
    const user = AuthService.getCurrentUser();
    console.log('***********************************************Hello! in home ', user);

    if (user) {
      console.log('Hello! setting currentUser ', user.username);
      const prenom = user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1);
      setCurrentUser(user);
      setIsFreemium(AuthService.checkFreemium());
      setIsAssure(AuthService.checkAssure());
      setUsername(prenom);

      UserService.getBadge(user.id).then(
        response => {
          if (response.data) {
            setBadges(response.data);
          } else {
            setBadges(false);
          }
          console.log('badges! ', response.data);
        },
        error => {
          console.log('*****************Error ', error);
        }
      );

      setIsLoading(false);
      console.log('***********************************************End! in home ', user);
    }
  }, []); // Le tableau de dépendances vide signifie que l'effet s'exécute uniquement au premier rendu



  // Définir la fonction showAllBadges
  const showAllBadges = () => {
    // Logique pour afficher tous les badges
    console.log('Afficher tous les badges');
  };

  // Définir la fonction showAllBadges
  const gotoSecondLife = () => {
    const tosecond=true;
    navigate(`/inventaire`,{ state: { tosecond } });
  };

  // Définir la fonction showAllBadges
  const gotoAddProduct = () => {
    const toadd=true;
    navigate(`/productlist`,{ state: { toadd } });
  };

  // Définir la fonction showAllBadges
  const gotoMaintenanceProduct = () => {
    const tomaintenance=true;
    navigate(`/productlist`,{ state: { tomaintenance } });
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 0
  };

  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }

  return (
        <div className="mt-4">
              <h1 className="mt-3" 
                        style={{ 
                        fontSize:"1.25em",
                        fontWeight: "600",
                        wordWrap: "break-word", // Permet de casser les mots à la fin de la ligne
                        wordBreak: "break-word"
                        }}>Hello {currentUser.firstname} !</h1>
              <span  style={{ 
                        fontSize:"0.875rem",
                        }}>Qu’est-ce que vous voulez faire aujourd’hui ?</span>
                
              <div className="mt-3" >
                  
                  <Slider {...settings} className="mt-3">
                    <div className="d-flex flex-column align-items-center link-text green-text"
                    onClick={() => gotoAddProduct()}> 
                      <div className="card-caroussel light-green-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Heart.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Ajouter</span>
                    </div>
                    <div  
                     className="d-flex flex-column align-items-center link-text"
                     onClick={() => gotoMaintenanceProduct()}>
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Maintenance.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Entretenir</span>
                    </div>
                    <a style={{textDecoration: 'none' }} href="/#/productlist" className="d-flex flex-column align-items-center link-text">
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Repair.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Réparer</span>
                    </a>
                    <div  className="d-flex flex-column align-items-center link-text"
                        onClick={() => gotoSecondLife()}> 
                      <div className="card-caroussel light-gray-background">
                      <img 
                        src={process.env.PUBLIC_URL + '/icons/Secondlife.svg'} 
                        style={{ width: 'auto', height: '2rem', verticalAlign: 'middle' }} alt="profil" />
                      </div>
                      <span>Seconde Vie</span>
                    </div>
                  </Slider>
                </div>
              

                <div className="mt-3" >
                  <h4 className=" title col-6 d-flex align-items-end fw-bold" >Mon Portefeuille</h4>
                  <div className="mb-3 mt-3 d-flex green-border" >
                  <div className="mt-2 mb-2 col-5 d-flex" style={{ position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                        {/* Texte centré au-dessus de l'image */}
                        <div style={{
                            position: 'absolute',
                            zIndex: 1, // Assurez-vous que le texte est au-dessus de l'image
                            textAlign: 'center',
                          }}>
                        <div style={{
                          color: 'gray', // Couleur du texte
                          fontSize: '0.875rem', // Taille du texte
                          fontWeight: '500', // Style de texte en gras
                          }}
                          >Total</div>
                        <div
                          style={{
                            color: 'black', // Couleur du texte
                            fontSize: '1rem', // Taille du texte
                            fontWeight: '500', // Style de texte en gras
                          }}
                        >
                          50
                        </div>
                        </div>
                        <img 
                        style={{ width: 'auto', height: '7.5rem', alignSelf: 'center', justifyContent : 'center'}} 
                        alt=""
                        src={chartUrl}
                          />
                      </div>
                      <div className="mt-2 mb-2 col-6" style={{ alignSelf: 'center', justifyContent : 'center', fontSize : '0.75rem', fontWeight: '400'}} >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                              style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: 'var(--color-green)',
                                marginRight: '8px', // Espacement entre le rond et le texte
                              }}
                            ></span>                       
                            <span>gros électroménagers: </span>
                            <span
                             style={{ 
                                fontWeight: '500'
                                }}>30</span>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                              style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: 'var(--color-light-green)',
                                marginRight: '8px', // Espacement entre le rond et le texte
                              }}
                            ></span>
                            <span>petit électroménagers:  </span>
                            <span
                             style={{ 
                                fontWeight: '500'
                                }}>20</span>
                          </div>
                      </div>
                     
                  </div>
                </div>
                <div>
                    <div className="row d-flex mb-1 mt-4" >
                        <h4 className=" title col-6 d-flex align-items-end fw-bold">
                          Mes Univers</h4>
                          <Link 
                              to="/inventaire/univers"
                              className="col-6 d-flex justify-content-end seemore-link align-items-end"
                              style={{ cursor: 'pointer' }} // Le curseur de la souris reste un pointeur cliquable
                            >
                              {/* Contenu de votre lien ici */}
                              Voir plus &rarr;
                            </Link>
                      </div>         
                      <div className="mt-3 d-flex row gx-2 mb-2 h-100">
                          <div className="col-6 col-md-3 col-lg-3 text-center mb-3 pe-2">
                            <Link to="/inventaire/univers" style={{ textDecoration: 'none', color: 'inherit' }}>
                              <div className="product-background">
                                <img src={process.env.PUBLIC_URL + '/icons/image/Kitchen.png'} alt="" className="img-fluid mb-2" />
                                <span>Cuisine</span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-6 col-md-3 col-lg-3 text-center mb-3 ps-2">
                            <Link to="/inventaire/univers" style={{ textDecoration: 'none', color: 'inherit' }}>
                              <div className="product-background">
                                <img src={process.env.PUBLIC_URL + '/icons/image/Bathroom.png'} alt="" className="img-fluid mb-2" />
                                <span>Salle de bain</span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-6 col-md-3 col-lg-3 text-center mb-3 pe-2">
                            <Link to="/inventaire/univers" style={{ textDecoration: 'none', color: 'inherit' }}>
                              <div className="product-background">
                                <img src={process.env.PUBLIC_URL + '/icons/image/Livingroom.png'} alt="" className="img-fluid mb-2" />
                                <span>Salon</span>
                              </div>
                            </Link>
                          </div>

                          <div className="col-6 col-md-3 col-lg-3 text-center mb-3 ps-2">
                            <Link to="/inventaire/univers" style={{ textDecoration: 'none', color: 'inherit' }}>
                              <div className="product-background">
                                <img src={process.env.PUBLIC_URL + '/icons/image/Bedroom.png'} alt="" className="img-fluid mb-2" />
                                <span>Chambre</span>
                              </div>
                            </Link>
                          </div>
                        </div>

                </div>
              
                <div>
                  {badges && badges.length > 0 && (
                    <div> 
                      <div className="row d-flex mb-1 mt-4" >
                        <h4 className=" title col-6 d-flex align-items-end fw-bold" >Mes Badges</h4>
                        <a 
                          className="col-6 d-flex justify-content-end seemore-link align-items-end"
                          onClick={showAllBadges}// Ajouter l'événement onClick
                          style={{ cursor: 'pointer' }} // Assurez-vous que le curseur indique un lien cliquable
                        >  
                        Voir plus &rarr; {/* Flèche droite simple */}</a>
                      </div>
                      <div className="row d-flex mb-3 mt-3"> 
                          {badges.map((badge) => (
                          <div className="col-3 col-md-2" key={badge.id} >
                              <AppBadge
                                  image={badge.refbadge.pict} 
                              />
                      </div>
                          ))}
                      </div>  
                    </div>
                  )}
                </div>
            </div> 
    );
  }


export default withRouter(Home);