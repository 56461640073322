import React, { useEffect, useState } from 'react';


export const EditIcon = '✎';


function Icon({ src, alt, className, onClick, style }) {
  const [svgContent, setSvgContent] = useState(null);
  const iconSrc = `${process.env.PUBLIC_URL}/icons/${src}`;

  useEffect(() => {
    if (src.endsWith('.svg')) {
        //console.log("Fetching icon from:", iconSrc);
        fetch(iconSrc)
            .then(response => {
                //console.log("Response status:", response.status);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then(data => {
                //console.log("SVG Content:", data);
                setSvgContent(data);
            })
            .catch(error => console.error("Error fetching the SVG:", error));
    }
}, [iconSrc, src]);


  if (svgContent) {
      return (
          <div 
              className={className} 
              onClick={onClick} 
              style={style} 
              aria-label={alt}
              dangerouslySetInnerHTML={{ __html: svgContent }}
          />
      );
  }

  return (
      <img 
          src={iconSrc} 
          alt={alt} 
          className={className} 
          onClick={onClick} 
          style={style} 
      />
  );
}

  function IconByType(type) {
    // Logique pour déterminer quelle icône renvoyer en fonction du type
    // Par exemple :
    const iconMap = {
      entretien: 'spray-can-sparkles.svg',
      reparation: 'wrench.svg',
      secondvie: 'hand-holding-heart.svg',
      default : 'book-open.svg',
    };
    switch (type) {
      case 'Entretien':
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.entretien} alt="Entretien" style={{ width: '30px' }} />;
      case 'Réparation':
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.reparation} alt="Reparation" style={{ width: '30px' }} />;
      case 'Secondevie':
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.reparation} alt="Seconde Vie" style={{ width: '30px' }} />;
      default:
        return <img src={process.env.PUBLIC_URL +'/icons/'+iconMap.default} alt="Default" style={{ width: '30px' }} />;
    }
  }

export { IconByType };
export default Icon;