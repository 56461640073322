import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/entretienproduct.css";

import { useNavigate } from 'react-router-dom'; // Remplace useHistory par useNavigate
import UniverService from '../../services/univer.service'; // Importez votre fonction getUniver si elle est dans un autre fichier


const Univers = () => {
    
    const navigate = useNavigate(); // Utilise useNavigate au lieu de useHistory

    // Définir l'état pour stocker les univers
    const [univers, setUnivers] = useState([]);

    // Utiliser useEffect pour récupérer les univers lorsque le composant est monté
    useEffect(() => {
        const fetchUnivers = async () => {
            try {
                const data = await UniverService.getUniver();  // Récupérer les univers
                setUnivers(data);  // Mettre à jour l'état avec les univers
                console.log('univers! ', data);
            } catch (error) {
                console.error("Erreur lors de la récupération des univers:", error);
            }
        };

        fetchUnivers();  // Appel de la fonction asynchrone pour récupérer les univers
    }, []);  // Tableau de dépendances vide pour exécuter l'effet au montage uniquement

    
    // Fonction appelée lorsqu'un univers est cliqué
    const gotoUniver = (currentUniver) => {
        if (currentUniver.univer_id !== -1) {
            // Rediriger vers univerdetail et passer l'objet currentUniver dans l'état
            navigate(`/inventaire/univers/${currentUniver.univer_id}/detail`, { state: { univer: currentUniver } });
          } else {
            // Rediriger vers univercreate
            navigate('/inventaire/univers/new');
          }
    };
    

    return (
        
             <div >
                <div className="row d-flex mb-1 mt-4" >
                    <h4 className=" title col-6 d-flex align-items-end fw-bold">
                    Mes Univers</h4>
                </div>         
                
                 {/* Affichage de la liste des univers */}
                 <div className="mt-4 d-flex row gx-2 mb-2 h-100">
                    {univers.map((univer, index) => (
                        <div className="col-6 col-md-3 col-lg-3 text-center mb-3 pe-2" 
                            key={index}
                            onClick={() => gotoUniver(univer)}
                            style={{ cursor: 'pointer' }}>
                            <div className="product-background mb-1"
                            >
                                <img 
                                    src={process.env.PUBLIC_URL + '/icons/image/' + univer.image} 
                                    alt={univer.name} 
                                    className="img-fluid mb-2" 
                                />
                                <span>{univer.name}</span>
                            </div>
                            <div className="light-gray-background" style={{ borderRadius: "4px", fontSize: "0.75rem", fontWeight: "400" }}>
                                {univer.nbAppliance === 0 
                                    ? 'Vide' 
                                    : univer.nbAppliance === 1 
                                        ? '1 appareil' 
                                        : `${univer.nbAppliance} appareils`}
                            </div>
                        </div>
                    ))}
                </div>


                <button className="mb-5 btn btn-primary btn-block">
                  <span>AJOUTER UN APPAREIL</span>
                    </button>
                </div>
    );
};

export default Univers;